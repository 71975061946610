<template>
  <div>
    <b-modal
      id="add-custom-order"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('CreateOrderMod')"

      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form
        class="fixed-height-modal"
        @reset="onCancel"
      >
        <div
          class="existingClient"
          style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
        >
          <b-button
            v-b-toggle.collapse-345
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <b-form-group
            style="width: 80%; padding-bottom: 10px;"
            :label="$t('SelectExistingClient')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == null"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 10px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; left: 200px; top: 17px; cursor: pointer"
                  @click="searchClients = null ,client= false, resetCustomerData()"
                />
                <input
                  v-model="searchClients"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button
            v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="addNewClient"
          >
            {{ $t(`Addclient`) }}
          </b-button>
        </div>

        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastName.$model"
                      :state="validateState('lastName')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                      :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                      @update="getFormattedNumber"
                    />
                  </b-form-group>
                </div>

                <b-form-group :label="`${$t('OrderType')}`">
                  <b-form-select
                    v-model="selectedOrderType"
                    dropup
                    :options="transformOrderTypes"
                    required
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        width: 67%;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                  <p
                    v-if="!selectedOrderType && isButtonSubmited"
                    style="margin-top: 0.25rem;
                          font-size: 0.875em;
                          color: #dc3545;"
                  >{{ $t('SelectOrderType') }}</p>
                </b-form-group>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>
        <section>
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 240px; height: 40px;margin:10px 0 "
            @click="addHouse"
          >
            {{ $t('AddHouse') }}
          </button>
          <p
            v-if="createHouseItemRequestDtos.length == 0 && isButtonSubmited "
            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
          >
            {{ $t('Please select a house') }}
          </p>
          <!-- <OrdersTree
            v-if="createHouseItemRequestDtos.length > 0"
            :create-house-item-request-dtos="createHouseItemRequestDtos"
            :is-button-submited="isButtonSubmited"
            @valueOfDate="datesOfOrder"
          /> -->
          <NewOrdersTree
            v-if="createHouseItemRequestDtos.length > 0"
            :create-house-item-request-dtos="createHouseItemRequestDtos"
            :is-button-submited="isButtonSubmited"
          />
        </section>

        <section
          class="custom-modal__wrapper"
          style="margin-bottom:10px;margin-top:10px"
        >
          <div style="display:flex;margin-bottom: 10px">
            <b-form-group :label="$t('TotalCostOfMaterial')">
              <b-form-input
                v-model="totalMaterialCost"
                style="width:65%"
                disabled
                type="number"
              />
            </b-form-group>
          </div>
          <div>
            <div>
              <p style="margin-bottom:5px;color:red;">
                {{ $t('IfUSelect') }}
              </p>
              <div style="display:flex;margin-bottom:10px;">
                <b-form-group
                  style="width:20%"
                  :label="$t('Transport')"
                >
                  <b-form-checkbox v-model="transport" />
                </b-form-group>
                <b-form-group
                  style="width:20%"
                  :label="$t('Montage')"
                >
                  <b-form-checkbox v-model="montage" />
                </b-form-group>
              </div>
              <div style="display:flex;gap: 15px">
                <div v-if="montage">
                  <label>{{ $t('MontageStart') }}</label>
                  <div style="display: flex;flex-direction: column;">
                    <date-picker
                      v-model="montageStart "
                      type="datetime"
                      :placeholder="$t('SelectD')"
                    />
                    <p
                      v-if="montageStart == null && montage && isButtonSubmited"
                      style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                    >
                      {{ $t('SelectD') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="montage"
                  style="display: flex;gap:10px;"
                >
                  <div>
                    <label style="padding-left: 25px;;">{{ $t('DurationHour') }}</label>
                    <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                      <b-icon-dash-circle
                        style="cursor:pointer"
                        @click="substractHour"
                      />
                      <b-form-input
                        v-model.number="durationTimeHour"
                        type="number"
                        :placeholder="$t('Hour')"
                      />
                      <b-icon-plus-circle
                        style="cursor:pointer"
                        @click="addHour"
                      />
                    </div>
                  </div>
                  <div>
                    <label style="padding-left: 25px;;">{{ $t('DurationMinutes') }}</label>
                    <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                      <b-icon-dash-circle
                        style="cursor:pointer"
                        @click="substractMinutes"
                      />
                      <b-form-input
                        v-model.number="durationTimeMinutes"
                        type="number"
                        :placeholder="$t('Minutes')"
                      />
                      <b-icon-plus-circle
                        style="cursor:pointer"
                        @click="addMinutes"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="montage">
                  <label>{{ $t('MontageEnd') }}</label>
                  <div style="display: flex;flex-direction: column;">
                    <date-picker
                      v-model="montageEnd "
                      type="datetime"
                      :placeholder="$t('SelectD')"
                      disabled
                    />
                    <p
                      v-if="montageEnd == null && montage && isButtonSubmited"
                      style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                    >
                      {{ $t('SelectD') }}
                    </p>
                  </div>
                </div>
                <div v-if="!montage">
                  <label>{{ $t('PickupDate') }}</label>
                  <div style="display: flex;flex-direction: column;">
                    <date-picker
                      v-model="pickUpDate "
                      type="datetime"
                      :placeholder="$t('SelectD')"
                    />
                    <p
                      v-if="pickUpDate == null && isButtonSubmited && !montage"
                      style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                    >
                      {{ $t('SelectD') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="!montage"
                  style="width:50%"
                >
                  <label>{{ $t('Location') }}</label>
                  <div style="display: flex;flex-direction: column;">
                    <vue-select
                      v-model="locationPickup"
                      style="width:70%"
                      :options="getLocationsMoving"
                      label="locationName"
                      :reduce="(e) => e.locationId"
                      :placeholder="$t('Select')"
                    />
                  </div>
                </div>
              </div>
              <div style="display:flex;gap:10px;">
                <div v-if="montage">
                  <label>{{ $t('MontageCost') }}</label>
                  <div style="display: flex;gap:5px">
                    <b-input
                      v-model.number="montageCost"
                      type="number"
                      :placeholder="$t('MontageCost')"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                    padding: 0.469rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <div v-if="transport">
                  <label>{{ $t('transportCost') }}</label>
                  <div style="display: flex;gap:5px">
                    <b-input
                      v-model.number="transportCost"
                      type="number"
                      :placeholder="$t('transportCost')"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                    padding: 0.469rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: flex-start; flex-direction: row;gap:10px;align-items:end;">
                <div>
                  <label>{{ $t('Price') }}</label>
                  <div style="display: flex;">
                    <b-input
                      v-model.number="price"
                      type="number"
                      style="margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                    padding: 0.469rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <div>
                  <b-button
                    v-if="!showDiscountForm"
                    type="button"
                    size="sm"
                    class="button"
                    style="width: 280px;margin-left: 10px;"
                    @click="showDiscountForm = true"
                  >
                    <p style="margin: 0">
                      + {{ $t('AddDiscount') }}
                    </p>
                  </b-button>
                </div>

                <div
                  v-if="showDiscountForm"
                  style="display: flex;justify-content: flex-start; align-items: end;gap:10px"
                >
                  <div style="display:flex;flex-direction: column;">
                    <label>{{ $t('Discount') }}</label>
                    <b-input
                      v-model.number="discount"
                      type="number"
                      default="0"
                      :disabled="price == 0 || price == ''"
                      style="margin-right: 5px;"
                    />
                  </div>

                  <b-form-select
                    v-model="discountType"
                    style="margin-right: 5px;height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                  >
                    <option value="$">
                      $
                    </option>
                    <option value="€">
                      €
                    </option>
                    <option value="CHF">
                      CHF
                    </option>
                    <option value="%">
                      %
                    </option>
                  </b-form-select>
                  <div>
                    <b-icon-trash
                      type="button"
                      size="sm"
                      style="background:white;color: black; height: 36px; width: 30px; padding: 5px; border-radius: 0.25rem;"
                      @click="showDiscountForm = false,resetDiscountedPrice() "
                    />
                  </div>
                  <div style="display:flex;flex-direction: column;">
                    <label>{{ $t('DiscountedPrice') }}</label>
                    <b-input
                      v-model.number="discountedPrice"
                      disabled
                      type="number"
                      style="width: 90%;"
                    />
                  </div>
                  <b-form-select
                    v-model="currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>{{ $t('PaidPrice') }}</label>
                </div>
                <div style="display:flex;">
                  <b-input
                    v-model.number="paidPrice"
                    type="number"
                    style="width:220px;margin-right: 5px;"
                  />
                  <b-form-select
                    v-model="currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>

              </div>
            </div>
            <div style="margin: 2% 1%; display: flex">
              <p class="p-0 m-0 ml-50">
                {{ $t('IncludeTax') }}
                <toggle-button
                  :value="includeTax"
                  :sync="true"
                  color="#242F6E"
                  @change="includeTax = !includeTax"
                />
              </p>
              <div
                v-if="includeTax == true"
                style=" margin-left: 10px; margin-bottom: 10px"
              >
                <span>18%</span>
              </div>
            </div>
            <b-form-group
              :label="$t('SellDate')"
            >
              <date-picker
                v-model="sellDate "
                type="date"
                format="YYYY-MM-DD"
                value-type="format"
                :placeholder="$t('SelectD')"
              />
            </b-form-group>
          </div>
        </section>
        <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
          <b-form-group
            :label="$t('ShippingCost')"
            style="width:30%"
          >
            <div

              style="display:flex;flex-direction:column;gap:5px;"
            >
              <div style="display:flex;flex-direction:row;gap:5px;">
                <b-form-input
                  v-model.number="shippings.cost"
                  :placeholder="$t('Cost')"
                  type="number"
                />
                <b-form-select
                  v-model="shippings.currency"
                  dropup
                  :options="[{ value: null, text: 'None' }, ...currencies]"
                  aria-placeholder="Select order type"
                  aria-describedby="input-1-live-feedback"
                  style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                />
              </div>
              <p
                v-if="((shippings.cost === '' || shippings.cost < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.paidAmount !== ''))"
                style="color:red"
              >{{ $t('FillData') }}</p>
              <b-form-group
                :label="$t('paidAmount')"
                style="width: 100%"
              />
              <b-form-input
                v-model.number="shippings.paidAmount"
                :placeholder="$t('paidAmount')"
                type="number"
              />
              <p
                v-if="((shippings.paidAmount === '' || shippings.paidAmount < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.cost !== '' || shippings.cost < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
              <b-form-group
                :label="$t('Notes')"
                style="width:100%"
              >
                <b-form-textarea
                  v-model="shippings.notes"
                  style="width:100%"
                  :placeholder="$t('Select')"
                  type="number"
                />
              </b-form-group>
              <p
                v-if="(shippings.notes === '' && (shippings.accountId !== null || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('SelectAccount')"
            style="margin-top:5px;margin-bottom:5px;width:30%"
          >
            <vue-select
              v-model="shippings.accountId"
              :options="getAccountDetails"
              required
              label="name"
              :reduce="(account) => account.accountId"
              :placeholder="$t('SelectAccount')"
              aria-describedby="input-1-live-feedback"
            />
            <p
              v-if="(shippings.accountId == null && (shippings.notes !== '' || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
              style="color:red"
            >{{ $t('FillData') }}</p>
          </b-form-group>
        </div>
        <b-form-group />
        <div style="display: flex;">
          <AddFile
            ref="addFile1"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadSketch')"
            :file-data-type="'Scetch'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile2"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadContract')"
            :file-data-type="'Contract'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile3"
            :folder="'OrderPhotos'"
            :label-text="$t('OtherDocuments')"
            :file-data-type="'OtherDocument'"
            :file-name="createFileDataDto"
          />
        </div>
        <div style="display: flex; gap: 10px; margin: 0px 0px 10px 5px">
          <b-form-group
            :label="$t('AddAccount')"
          >
            <vue-select
              v-model="accountId"
              :options="getAccountDetails"
              :reduce="(e) => e.accountId"
              label="name"
              :placeholder="$t('Select')"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            v-if="(getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales') || filiale.storeId == null"
            :label="$t('Filiale')"
          >
            <vue-select
              v-model="filiale"
              :options="filteredStores"
              label="storeName"
              :clearable="false"
              :placeholder="$t('SelectFiliale')"
            />
            <p
              v-if="filiale.storeId == null"
              style="color:red;"
            >
              Please select a filiale
            </p>
            <p
              v-else-if="getLoggedInUser.storeId == filiale.storeId"
              style="color:red;"
            >
              {{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }} is in this store
            </p>
          </b-form-group>
          <b-form-group
            v-if="getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales' "
            :label="$t('SelectSalesPerson')"
          >
            <vue-select
              v-model="roleId"
              :options="getUsersMultipleRoles"
              required
              label="fullName"
              :reduce="(account) => account.userID"
              :placeholder="$t('SelectSalesPerson')"
              aria-describedby="input-1-live-feedback"
              style="width: 100%;"
            />
          </b-form-group>
        </div>
        <b-form-group
          :label="$t('Notes')"
          style="margin-top: 20px;"
        >
          <b-form-textarea
            id="textarea"
            v-model="description"
            :placeholder="$t('RequestedChangesByCustomer')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('RequiredFields') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            style="margin-top: 20px;"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit()"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';

import AWS from 'aws-sdk';
// import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { AWSurl, client } from '../domainConfig';
import AddFile from './AddFile.vue'
// import OrdersTree from '../components/orders/OrdersTree.vue'
import NewOrdersTree from '../components/orders/NewOrdersTree.vue'
import 'vue2-datepicker/index.css';

export default {
  components: {
    AddFile,
    // OrdersTree,
    DatePicker,
    NewOrdersTree,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  id: {
    type: Object,
  },
  data() {
    return {
      filiale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      oldFiliale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      sellDate: null,
      montageCost: 0,
      transportCost: 0,
      durationTimeMinutes: 0,
      durationTimeHour: 0,
      transport: true,
      montage: true,
      pickUpDate: null,
      locationPickup: null,
      includeTax: false,
      taxRate: 0,
      montageStart: null,
      montageEnd: null,
      roleId: null,
      accountId: null,
      isButtonSubmited: false,
      body: [],
      clienti: client.clientName,
      client: false,
      loading: false,
      s3: null,
      bucketName: AWSurl.bucketName,
      fileData: [],
      arrowCounter: 0,
      enterPressed: false,
      searchClients: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      noSearch: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      selectedOrderType: null,
      pickUpInStore: false,
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      description: '',
      createFileDataDto: [],
      form: [],
      error: {
        oType: '',
        duplicateError: '',
      },
      createHouseItemRequestDtos: [],
      price: 0,
      discount: 0,
      currencies: ['€', '$', 'CHF'],
      discountType: '€',
      showDiscountForm: false,
      paidPrice: 0,
      currency: '€',
      shippings: {
        cost: '',
        accountId: null,
        currency: null,
        notes: '',
        paidAmount: '',
      },
    };
  },

  validations: {
    selectedOrderType: {
      required,
    },
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getUsersMultipleRoles',
      'getLoggedInUser',
      'getAllStoresDropdown',
      'getLocationsMoving']),
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => { return store.storeName !== 'all' && store.storeName !== 'unassigned' });

      const allOption = { storeId: null, storeName: 'No filiale', storeGLN: null };
      const unassigned = { storeId: '00000000-0000-0000-0000-000000000000', storeName: 'Unassigned', storeGLN: null };
      return [allOption, unassigned, ...filteredStores];
    },
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.price - this.discount;
        } if (this.discountType === '%') {
          if (this.price === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.discount / 100) * this.price;
          return this.price - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.discount = this.price - value;
        } else if (this.discountType === '%') {
          if (this.price === 0) {
            this.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.price - value) / (this.price * 100);
            this.discount = percentageDiscount;
          }
        }
      },
    },
    totalMaterialCost() {
      let totalCost = 0;

      const calculateCost = (materialDtos) => {
        materialDtos.forEach((material) => {
          const price = parseFloat(material.pricePerMeterWithDiscount);
          const quantityOrLength = material.quantity > 0 ? material.quantity : parseFloat(material.length);
          totalCost += price * quantityOrLength;
        });
      };

      this.createHouseItemRequestDtos.forEach((house) => {
        house.createFloorRequestDtos.forEach((floor) => {
          floor.createRoomRequestDtos.forEach((room) => {
            room.createWindowRequestDtos.forEach((window) => {
              calculateCost(window.materialVariantInStoreDtos);
              if (window.createWindowDecorativeRequestDtos) {
                window.createWindowDecorativeRequestDtos.forEach((decorativeWindow) => {
                  calculateCost(decorativeWindow.materialVariantInStoreDtos);
                });
              }
            });
          });
        });
      });

      return Math.ceil(totalCost);
    },
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
  },
  watch: {
    'getLoggedInUser.storeId': function () {
      this.updateFiliale();
    },
    'shippings.cost': function (newCost) {
      if (newCost) {
        this.shippings.paidAmount = 0;
        this.shippings.currency = '€'
      }
      if (newCost < 0) {
        this.shippings.cost = 0
      }
    },
    'shippings.paidAmount': function (newCost) {
      if (newCost > this.shippings.cost) {
        this.shippings.paidAmount = 0;
      }
      if (newCost < 0) {
        this.shippings.paidAmount = 0
      }
    },
    montageCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.montageCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    transportCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.transportCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    totalMaterialCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.totalMaterialCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    montageStart(value) {
      if (value) {
        this.calculateMontageEnd();
      }
    },
    durationTimeHour(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeHour = 0;
        } else if (value === '') {
          this.durationTimeHour = 0;
        } else {
          const fullHours = Math.floor(value);
          const fractionalPart = value - fullHours;

          this.durationTimeHour = fullHours;
          this.durationTimeMinutes += Math.round(fractionalPart * 60);
        }
        this.calculateMontageEnd()
      }, 0);
    },
    durationTimeMinutes(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeMinutes = 0
        }
        if (value == '') {
          this.durationTimeMinutes = 0;
        }
        if (value >= 60) {
          const totalHours = Math.floor(value / 60);
          this.durationTimeHour += totalHours;

          const fractionalPart = (value / 60) - totalHours;
          const remainingMinutes = Math.round(fractionalPart * 60);

          this.durationTimeMinutes = remainingMinutes;
        }
        if (value < 0 && this.durationTimeHour > 0) {
          this.durationTimeHour -= 1
          this.durationTimeMinutes = 45
        }
        this.calculateMontageEnd()
      }, 0);
    },
    montage(value) {
      if (value == true) {
        this.pickUpDate = null
        this.locationPickup = null
      } else {
        this.montageStart = null
        this.montageEnd = null
        this.durationTimeMinutes = 0
        this.durationTimeHour = 0
        this.montageCost = 0
      }
    },
    transport(value) {
      if (value == false) {
        this.transportCost = 0
      }
    },
    price(newPrice) {
      if (newPrice < this.discount) {
        this.discount = 0;
      }
    },
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.taxRate = 18;
      } else {
        this.taxRate = 0;
      }
    },
    // eslint-disable-next-line func-names
    discount(newDiscount) {
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.price && newDiscount > this.discountedPrice) {
          this.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (this.getLoggedInUser.role === 'SalesPerson' && newDiscount > 10) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role === 'Manager' && newDiscount > 20) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role !== 'Manager' && this.getLoggedInUser.role !== 'SalesPerson' && this.getLoggedInUser.role !== 'CEO' && this.getLoggedInUser.role !== 'Admin' && newDiscount > 5) {
          this.discount = 0;
        } else if (newDiscount > 100) {
          this.discount = 0;
        }
      }
    },
    form: {
      deep: true,
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  mounted() {
    if (this.getOrderTypes.length == 0) {
      this.loadOrderTypes()
    }
    this.usersByMultipleRoles({
      status: 'true',
      object: ['SalesPerson', 'OnlineSales'],
    })
    this.movingLocations()
    this.loadAllStoresByDropdown({
      amountOfRecords: 100,
    })
      .then(() => {
        this.updateFiliale()
      })
      .catch((error) => {
        console.error('Error in loading stores or updating Filiale:', error)
      })
  },
  methods: {
    ...mapActions([
      'usersByMultipleRoles', 'loadAllStoresByDropdown', 'movingLocations', 'createOrder', 'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    updateFiliale() {
      const storeId = this.getLoggedInUser.storeId?.toUpperCase();

      if (storeId) {
        const store = this.filteredStores.find((stores) => { return stores.storeId === storeId });
        if (store) {
          this.filiale = {
            storeId: store.storeId,
            storeName: store.storeName,
            storeGLN: store.storeGLN,
          };
          this.oldFiliale = {
            storeId: store.storeId,
            storeName: store.storeName,
            storeGLN: store.storeGLN,
          };
        } else {
          this.filiale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
          this.oldFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
        }
      } else {
        this.filiale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
        this.oldFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
      }
    },
    updateTotalPrice() {
      this.price = this.totalMaterialCost + this.montageCost + this.transportCost;
    },
    calculateMontageEnd() {
      if (this.montageStart && this.durationTimeHour != null && this.durationTimeMinutes != null) {
        const startDate = new Date(this.montageStart);

        startDate.setHours(startDate.getHours() + this.durationTimeHour);
        startDate.setMinutes(startDate.getMinutes() + this.durationTimeMinutes);

        startDate.setSeconds(0);

        this.montageEnd = startDate;

        console.log('Montage End:', this.montageEnd);
      }
    },
    addHour() {
      this.durationTimeHour++;
      this.calculateMontageEnd();
    },
    substractHour() {
      this.durationTimeHour--;
      this.calculateMontageEnd();
    },
    addMinutes() {
      this.durationTimeMinutes += 15
      this.calculateMontageEnd();
    },
    substractMinutes() {
      this.durationTimeMinutes -= 15
      this.calculateMontageEnd();
    },
    datesOfOrder(value) {
      console.log('value', value)
      this.montageValue = value.montage
      this.pickupValue = value.pickup
    },
    addHouse() {
      const houseNumber = this.createHouseItemRequestDtos.length + 1;
      this.createHouseItemRequestDtos.push({
        name: `Shtepia nr ${houseNumber}`, createFloorRequestDtos: [], editable: false,
      });
    },
    addNewClient() {
      this.client = true;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber
        this.staticForm.ordererDto.countryCode = n.countryCode
      } else {
        this.staticForm.ordererDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      this.body = clientData
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },

    async onSubmit() {
      this.isButtonSubmited = true
      this.$v.staticForm.ordererDto.$touch();

      const hasInvalidData = this.createHouseItemRequestDtos.length === 0
    || this.createHouseItemRequestDtos.some((house) => {
      return house.createFloorRequestDtos.length === 0
      || house.createFloorRequestDtos.some((floor) => {
        return floor.createRoomRequestDtos.length === 0
        || floor.createRoomRequestDtos.some((room) => {
          return room.createWindowRequestDtos.length === 0
          || room.createWindowRequestDtos.some((window) => {
            return window.width <= 0 || window.height <= 0 || !window.sewingType
            || window.materialVariantInStoreDtos.length === 0
            || window.materialVariantInStoreDtos.some((material) => { return !material.name && material.quantity < 0 && material.length < 0 && material.pricePerMeterWithDiscount })
          })
        })
      })
    });
      if (this.$v.staticForm.ordererDto.$anyError) {
        return;
      }
      if (this.selectedOrderType == null) {
        return;
      }

      if (hasInvalidData) {
        return;
      }
      if (this.montage && this.montageStart == null && this.montageEnd == null) {
        return;
      }
      if (!this.montage && this.pickUpDate == null) {
        return;
      }
      if (
        (this.shippings.cost < 0 && (this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.accountId !== null && (this.shippings.cost === '' || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.currency !== null && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.notes !== '' && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.paidAmount === ''))
      || (this.shippings.paidAmount < 0 && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === ''))
      ) {
        return;
      }

      if (
        this.shippings.cost === ''
      && this.shippings.accountId === null
      && this.shippings.currency === null
      && this.shippings.notes === ''
      && this.shippings.paidAmount === ''
      ) {
        this.shippings.isEmpty = true;
      } else {
        this.shippings.isEmpty = false;
      }
      const objekti = {
        createHouseItemRequestDtos: this.createHouseItemRequestDtos,
        ordererDto: this.staticForm.ordererDto,
        billingAddressDto: this.staticForm.billingAddressDto,
        shippingAddressDto: this.staticForm.shippingAddressDto,
        createFileDataDto: this.createFileDataDto,
        clientId: this.clientId,
        orderType: this.selectedOrderType,
        pickUpInStore: this.pickUpInStore,
        salesUserId: this.roleId,
        storeId: this.filiale.storeId,
        taxRate: this.taxRate,
        montageStart: this.montageStart,
        montageEnd: this.montageEnd,
        price: this.price,
        paidAmount: this.paidPrice,
        paidNotes: null,
        discount: this.discount,
        description: this.description,
        currency: '$',
        accountId: this.accountId,
        pickUpDate: this.pickUpDate,
        pickUpLocationId: this.locationPickup,
        shippingCostRequestDto: this.shippings.isEmpty ? null : this.shippings,
        invoiceRemark: null,
        sellDate: this.sellDate,
      }
      objekti.createHouseItemRequestDtos.forEach((houseItem) => {
        delete houseItem.editable;
        houseItem.createFloorRequestDtos.forEach((floorItem) => {
          delete floorItem.editable;
          floorItem.createRoomRequestDtos.forEach((roomItem) => {
            delete roomItem.editable;
            roomItem.createWindowRequestDtos.forEach((windowItem) => {
              if (windowItem.sewingType && windowItem.sewingType.value) {
                windowItem.sewingType = windowItem.sewingType.value;
              }

              delete windowItem.editable
              delete windowItem.previousHingType;
              windowItem.materialVariantInStoreDtos.forEach((variant) => {
                const quantity = variant.quantity;
                const length = parseFloat(variant.length) || 0;
                if (quantity > 0 || length > 0) {
                  variant.quantity = Math.max(quantity, length);
                } else {
                  variant.quantity = 0;
                }
                delete variant.length;
              });
              windowItem.createWindowDecorativeRequestDtos.forEach((windowVariant) => {
                if (windowVariant.sewingType && windowVariant.sewingType.value) {
                  windowVariant.sewingType = windowVariant.sewingType.value;
                }
                delete windowVariant.editable;
                windowVariant.name = `${windowItem.name} - ${windowItem.hingType}`;
                windowVariant.hingType = windowItem.hingType;
                windowVariant.materialVariantInStoreDtos = windowVariant.materialVariantInStoreDtos.map((variant) => {
                  if (variant.name === null) {
                    return [];
                  }
                  const quantity = variant.quantity;
                  const length = parseFloat(variant.length) || 0;
                  if (quantity > 0 || length > 0) {
                    variant.quantity = Math.max(quantity, length);
                  } else {
                    variant.quantity = 0;
                  }
                  delete variant.length;
                  return variant;
                }).filter(Boolean);
              });
            });
          });
        });
      });
      this.createOrder({
        object: objekti,
      })
      this.$refs.modal.hide();
      this.resetForm()
      this.resetCustomerData()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.resetCustomerData()
      this.resetForm()
    },
    resetShippingAddress() {
      this.$v.staticForm.shippingAddressDto.$reset()
    },
    resetForm() {
      this.sellDate = null
      this.shippings.cost = ''
      this.shippings.currency = null
      this.shippings.accountId = null
      this.shippings.notes = ''
      this.shippings.paidAmount = ''
      this.durationTimeHour = 0
      this.durationTimeMinutes = 0
      this.montageCost = null
      this.transportCost = null
      this.price = 0
      this.discout = 0
      this.paidPrice = 0
      this.accountId = null
      this.roleId = null
      this.isButtonSubmited = false
      this.createHouseItemRequestDtos = []
      this.orderNumber = '';
      this.client = false;
      this.body = null
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.error.oType = ''
      this.resetCustomerData();
      this.isButtonSubmited = false;
      this.description = '';
      this.montageStart = null
      this.montageEnd = null
      this.taxRate = 0
      this.includeTax = false
      this.pickUpDate = null
      this.locationPickup = null
      this.clearCustomMeasurements()
      this.filiale = this.oldFiliale
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = null;
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastName = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = null;
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },

  },
};
</script>

<style lang="scss" scoped>
.custom-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;
}

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}


.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }


input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
}

</style>
