<template>
  <div class="houseDetail">
    <section
      v-for="(item, index) in createHouseItemRequestDtos"
      :key="index"
      class="sectionClass"
    >
      <div class="homeEdit">
        <div class="homeName">
          <img src="../../assets/images/houseImage.svg">
          <b-form-input
            v-if="item.editable"
            v-model="item.name"
            type="text"
            class="editInput"
            @blur="saveHouseName(index)"
            @keydown.enter="saveHouseName(index)"
          />
          <span v-else>{{ item.name }}</span>
        </div>
        <div class="iconsClass">
          <b-icon-pencil
            v-if="!item.editable"
            style="cursor:pointer"
            @click="toggleEditHouseName(index)"
          />
          <b-icon-check-circle
            v-if="item.editable"
            style="cursor:pointer"
            @click="toggleEditHouseName(index)"
          />
          <b-icon-trash
            style="cursor:pointer;"
            @click="removeHouse(index)"
          />
          <button
            v-if="item.createFloorRequestDtos.length == 0"
            class="buttonNotaSmall"
            type="button"
            @click="addFloor(index)"
          >
            Shto Katin e ri
          </button>
          <b-button
            variant="link"
            class="collapse-button"
            @click="toggleCollapse(index)"
          >
            <b-icon-chevron-down v-if="!isCollapsed(index)" />
            <b-icon-chevron-up v-if="isCollapsed(index)" />
          </b-button>
        </div>
      </div>
      <p
        v-if="item.createFloorRequestDtos.length == 0 && isButtonSubmited "
        class="validationClass"
      >
        {{ $t('Please select a floor') }}
      </p>
      <b-collapse :visible="!isCollapsed(index)">
        <hr
          v-if="item.createFloorRequestDtos.length > 0"
          class="hrClass"
        >
        <div :class="isCollapsedFloor(index) ? 'buttonOnly' : 'buttonAndCollapse'">
          <p
            v-if="isCollapsedFloor(index)"
            style="margin: 0px;font-weight: 600"
          >
            Floor closed
          </p>
          <b-collapse
            :visible="!isCollapsedFloor(index)"
          >
            <div class="allClases">
              <div
                v-for="(floor, floorIndex) in item.createFloorRequestDtos"
                :key="floorIndex"
                class="floorWrapper"
              >
                <button
                  class="objectsClass"
                  type="button"
                  :class="{ 'selectedFloor': selectedFloorIndex === floorIndex }"
                  @click="selectFloorToAddRoom(index, floorIndex)"
                >
                  <span class="iconsFloor">
                    <b-icon-pencil
                      v-if="!floor.editable"
                      @click="toggleEditFloorName(index, floorIndex)"
                    />
                    <b-icon-check-circle
                      v-if="floor.editable"
                      @click="toggleEditFloorName(index, floorIndex)"
                    />
                    <b-icon-trash @click="removeFloor(index, floorIndex)" />
                  </span>
                  <span class="nameFloor">
                    <img
                      src="../../assets/images/HomeImage.svg"
                      style="height: 60px;"
                    >
                    <b-form-input
                      v-if="floor.editable"
                      v-model="floor.name"
                      type="text"
                      class="editInput"
                      @blur="saveFloorName(index, floorIndex)"
                      @keydown.enter="saveFloorName(index, floorIndex)"
                    />
                    <span v-else>{{ floor.name }}</span>
                  </span>
                </button>
              </div>
              <div v-if="item.createFloorRequestDtos.length > 0">
                <button
                  class="buttonNota"
                  type="button"
                  @click="addFloor(index)"
                >
                  Shto Katin e ri
                </button>
              </div>

            </div>
          </b-collapse>
          <div
            v-if="item.createFloorRequestDtos.length > 0"
            class="buttonsCollapsed"
          >
            <b-button
              variant="link"
              class="collapse-button"
              @click="toggleCollapseFloor(index)"
            >
              <b-icon-chevron-down v-if="!isCollapsedFloor(index)" />
              <b-icon-chevron-up v-if="isCollapsedFloor(index)" />
            </b-button>
          </div>
        </div>
        <hr
          v-if="item.createFloorRequestDtos.length > 0"
          class="hrClass"
        >
        <div :class="isCollapsedRoom(index) ? 'buttonOnly' : 'buttonAndCollapse'">
          <p
            v-if="isCollapsedRoom(index)"
            style="margin: 0px;font-weight: 600"
          >
            Room closed
          </p>
          <b-collapse
            :visible="!isCollapsedRoom(index)"
          >
            <div class="allClases">
              <div
                v-for="(room, roomIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos"
                :key="roomIndex"
                class="floorWrapper"
              >
                <button
                  class="objectsClass"
                  type="button"
                  :class="{ 'selectedFloor': selectedRoomIndex === roomIndex }"
                  @click="selectRoomToAddObject(index, selectedFloorIndex, roomIndex)"
                >
                  <span class="iconsFloor">
                    <b-icon-pencil
                      v-if="!room.editable"
                      @click="toggleEditRoomName(index, selectedFloorIndex, roomIndex)"
                    />
                    <b-icon-check-circle
                      v-if="room.editable"
                      @click="toggleEditRoomName(index, selectedFloorIndex, roomIndex)"
                    />
                    <b-icon-trash @click="removeRoom(index, selectedFloorIndex, roomIndex)" />
                  </span>
                  <span class="nameFloor">
                    <img
                      src="../../assets/images/RoomImage.svg"
                      style="height: 60px;"
                    >
                    <b-form-input
                      v-if="room.editable"
                      v-model="room.name"
                      type="text"
                      class="editInput"
                      @blur="saveRoomName(index, selectedFloorIndex, roomIndex)"
                      @keydown.enter="saveRoomName(index, selectedFloorIndex, roomIndex)"
                    />
                    <span v-else>{{ room.name }}</span>
                  </span>
                </button>
              </div>
              <div
                v-if="item.createFloorRequestDtos.length > 0"
              >
                <button
                  class="buttonNota"
                  type="button"
                  @click="addRoom(index, selectedFloorIndex)"
                >
                  Shto Dhomen e re
                </button>

              </div>
            </div>
          </b-collapse>
          <div
            v-if="item.createFloorRequestDtos.length > 0"
            class="buttonsCollapsed"
          >
            <b-button
              variant="link"
              class="collapse-button"
              @click="toggleCollapseRoom(index)"
            >
              <b-icon-chevron-down v-if="!isCollapsedRoom(index)" />
              <b-icon-chevron-up v-if="isCollapsedRoom(index)" />
            </b-button>
          </div>
        </div>
        <p
          v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length == 0 && isButtonSubmited "
          class="validationClass"
        >
          {{ $t('Please select a room') }}
        </p>
        <hr
          v-if="item.createFloorRequestDtos.length > 0"
          class="hrClass"
        >
        <div :class="isCollapsedObject(index) ? 'buttonOnly' : 'buttonAndCollapse'">
          <p
            v-if="isCollapsedObject(index)"
            style="margin: 0px;font-weight: 600"
          >
            Object closed
          </p>
          <b-collapse
            :visible="!isCollapsedObject(index)"
          >
            <div class="allClases">
              <div
                v-for="(window, windowIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos"
                :key="windowIndex"
                class="floorWrapper"
              >
                <button
                  class="objectsClass"
                  type="button"
                  :class="{ 'selectedFloor': selectedWindowIndex === windowIndex }"
                  @click="selectWindowToObject(index, selectedFloorIndex, selectedRoomIndex, windowIndex)"
                >
                  <span class="iconsFloor">
                    <b-icon-pencil
                      v-if="!window.editable"
                      @click="toggleEditWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                    />
                    <b-icon-check-circle
                      v-if="window.editable"
                      @click="toggleEditWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                    />
                    <b-icon-trash @click="removeWindow(index, selectedFloorIndex, selectedRoomIndex, windowIndex)" />
                  </span>
                  <span class="nameFloor">
                    <img
                      src="../../assets/images/ObjectImage.svg"
                      style="height: 60px;"
                    >
                    <b-form-input
                      v-if="window.editable"
                      v-model="window.name"
                      type="text"
                      class="editInput"
                      @blur="saveWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                      @keydown.enter="saveWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                    />
                    <span v-else>{{ window.name }}</span>
                  </span>
                </button>
              </div>
              <button
                v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length > 0"
                class="buttonNota"
                type="button"
                @click="addWindow(index)"
              >
                Shto Objektin e ri
              </button>
            </div>
          </b-collapse>
          <div
            v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length > 0"
            class="buttonsCollapsed"
          >
            <b-button
              variant="link"
              class="collapse-button"
              @click="toggleCollapseObject(index)"
            >
              <b-icon-chevron-down v-if="!isCollapsedObject(index)" />
              <b-icon-chevron-up v-if="isCollapsedObject(index)" />
            </b-button>
          </div>
        </div>
        <p
          v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos.length == 0 && isButtonSubmited "
          class="validationClass"
        >
          {{ $t('Please select a object') }}
        </p>
        <div v-if="selectedWindowIndex !== null">
          <div :class="isCollapsedObjectMaterials(index) ? 'buttonOnly' : 'buttonAndCollapse'">
            <p
              v-if="isCollapsedObjectMaterials(index)"
              style="margin: 5px 0px 0px 0px;font-weight: 600"
            >
              Materials closed
            </p>
            <b-collapse
              :visible="!isCollapsedObjectMaterials(index)"
              style="width:100%"
            >
              <div
                v-for="(window, windowIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos"
                :key="windowIndex"
              >
                <div
                  v-if="windowIndex === selectedWindowIndex"
                  class="allMaterialsDetails"
                >
                  <label style="font-size: 20px;font-weight:500">{{ windowIndex + 1 }}.{{ $t('WorkingNow') }} : {{ window.name }} </label>
                  <div style="display:flex;">
                    <div class="ordersService">
                      <b-form-checkbox
                        v-model="window.orderOtherService.pattern"
                      />
                      <label style="font-size: 25px">{{ $t('InPattern') }} </label>
                    </div>
                    <div class="ordersService">
                      <b-form-checkbox
                        v-model="window.orderOtherService.sewing"
                      />
                      <label style="font-size: 25px">{{ $t('Sewing') }} </label>
                    </div>
                    <div class="ordersService">
                      <b-form-checkbox
                        v-model="window.orderOtherService.transport"
                      />
                      <label style="font-size: 25px">{{ $t('Transport') }} </label>
                    </div>
                    <div class="ordersService">
                      <b-form-checkbox
                        v-model="window.orderOtherService.montage"
                        @input="checkMontage(window.orderOtherService.montage, window)"
                      />
                      <label style="font-size: 25px">{{ $t('Montage') }} </label>
                    </div>
                  </div>
                  <div style="display: flex;gap:15px">
                    <b-form-group
                      :label="$t('HingType')"
                      style="width: 20%;"
                    >
                      <vue-select
                        v-model="window.hingType"
                        :options="hingeTypes"
                        label="name"
                        :reduce="(e) => e.value"
                        :placeholder="$t('Select')"
                        @input="updateDecorative(index, window)"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="window.orderOtherService.montage == true"
                      :label="$t('MontageStart')"
                    >
                      <date-picker
                        v-model="window.montageStart"
                        type="datetime"
                        :placeholder="$t('SelectD')"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="window.orderOtherService.montage == true"
                      :label="$t('MontageEnd')"
                    >
                      <date-picker
                        v-model="window.montageEnd"
                        type="datetime"
                        :placeholder="$t('SelectD')"
                      />
                    </b-form-group>
                  </div>
                  <div style="display: flex;gap:15px">
                    <b-form-group
                      :label="$t('Width')"
                    >
                      <b-form-input
                        v-model.number="window.width"
                        :placeholder="$t('0')"
                        @input="updateMaterialLengthOrQuantity(window)"
                      />
                      <p
                        v-if="window.width < 0.0001 && isButtonSubmited "
                        class="validationClass"
                      >
                        {{ $t('TheValue') }}
                      </p>
                    </b-form-group>
                    <b-form-group
                      :label="$t('Height')"
                    >
                      <b-form-input
                        v-model.number="window.height"
                        :placeholder="$t('0')"
                      />
                      <p
                        v-if="window.height < 0.0001 && isButtonSubmited "
                        class="validationClass"
                      >
                        {{ $t('TheValue') }}
                      </p>
                    </b-form-group>
                    <b-form-group
                      :label="$t('SewingType')"
                      style="width: 30%;"
                    >
                      <vue-select
                        v-model="window.sewingType"
                        :options="sewingTypes"
                        label="name"
                        :reduce="(e) => e.value"
                        :placeholder="$t('Select')"
                        @input="handleSewingMethod(window)"
                      />
                      <p
                        v-if="window.sewingType == null && isButtonSubmited "
                        class="validationClass"
                      >
                        {{ $t('Please select a sewing type') }}
                      </p>
                    </b-form-group>
                  </div>
                  <div
                    v-for="(material, materialIndex) in window.materialVariantInStoreDtos"
                    :key="materialIndex"
                    class="materialsClass"
                  >
                    <div style="display:flex;justify-content: space-between;padding-right: 80px">
                      <label> {{ materialIndex + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ material.parameter ? `: ${material.parameter}`: null }} </span></label>
                      <b-icon-trash
                        style="cursor:pointer;"
                        @click="removeMaterial(index, materialIndex)"
                      />
                    </div>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="material.measurementType"
                        style="display:flex;gap: 10px;width:90%"
                        @change="resetMaterialName(material, window)"
                      >
                        <b-form-radio
                          class="form-radio"
                          value="Meter"
                        >
                          <p>{{ $t('Meter') }} </p>
                        </b-form-radio>
                        <b-form-radio
                          class="form-radio"
                          value="Part"
                        >
                          <p>{{ $t('Part') }} </p>
                        </b-form-radio>
                        <b-form-radio
                          class="form-radio"
                          value="Verse"
                        >
                          <p>{{ $t('Verse') }} </p>
                        </b-form-radio>
                        <b-form-radio
                          class="form-radio"
                          value="Package"
                        >
                          <p>{{ $t('Package') }} </p>
                        </b-form-radio>
                        <b-form-radio
                          class="form-radio"
                          value="Pair"
                        >
                          <p>{{ $t('Pair') }} </p>
                        </b-form-radio>
                        <b-form-radio
                          class="form-radio"
                          value="Set"
                        >
                          <p>{{ $t('Set') }} </p>
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div style="display:flex;gap:10px;width:100%">
                      <b-form-group
                        :label="$t('MaterialVariant')"
                        style="width:25%"
                      >
                        <vue-select
                          v-model="material.name"
                          :placeholder="$t('Description')"
                          label="materialVariantName"
                          :options="getMaterialOptions(material.measurementType)"
                          @search="searchMaterial(material.measurementType, $event)"
                          @input="updateMaterialVariantId(material.name, material)"
                        >
                          <li
                            slot="list-footer"
                            class="pagination"
                            style="display: flex; width: 100%; justify-content: center;"
                          >
                            <div
                              v-if="getMaterialVariantColorNameLike.length > 1"
                              style="display:flex;gap:5px; padding-top: 3px;width:98%"
                            >
                              <button
                                class="buttonCancel"
                                type="button"
                                :disabled="pageNumberFilter == 1"
                                @click="previousPage(material.measurementType, searchQuery)"
                              >
                                {{ $t('Previous') }}
                              </button>
                              <button
                                class="buttonSubmit"
                                type="button"
                                @click="nextPage(material.measurementType, searchQuery)"
                              >
                                {{ $t('Next') }}
                              </button>
                            </div>
                          </li>
                        </vue-select>
                        <p
                          v-if="(material.name == null || material.name == '' )&& isButtonSubmited "
                          class="validationClass"
                        >
                          {{ $t('Please Select a material variant') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        v-if="material.measurementType == 'Part' || material.measurementType == 'Parts'"
                        :label="$t(material.measurementType)"
                      >
                        <b-input
                          v-model.number="material.quantity"
                          required
                          type="number"
                          @input="calculateTotalPerMeter(material.pricePerMeter, material.quantity)"
                        />
                        <p
                          v-if="material.quantity < 0.0001 && isButtonSubmited "
                          class="validationClass"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        v-else
                        :label="$t(material.measurementType)"
                      >
                        <b-input
                          v-model.number="material.length"
                          required
                          type="number"
                          @input="calculateTotalPerMeter(material.pricePerMeter, material.length)"
                        />
                        <p
                          v-if="material.length < 0.0001 && isButtonSubmited "
                          class="validationClass"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        :label="$t('pricePerMeter')"
                      >
                        <b-input
                          v-model.number="material.pricePerMeter"
                          required
                          disabled
                          type="number"
                          @input="calculateTotalPerMeter(material.pricePerMeter, material.length || material.quantity)"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('PriceDiscount')"
                      >
                        <b-input
                          v-model.number="material.pricePerMeterWithDiscount"
                          required
                          type="number"
                          @input="calculateTotalPerMeter(material.pricePerMeterWithDiscount, material.length || material.quantity)"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <button
                    class="buttonNotaSmall"
                    type="button"
                    @click="addMaterialObject(index, window)"
                  >
                    Shto Material
                  </button>
                  <p
                    v-if="window.materialVariantInStoreDtos.length == 0 && isButtonSubmited"
                    class="validationClass"
                  >
                    {{ $t('AddMaterial') }}
                  </p>
                  <div
                    v-for="(windowDecorative, windowDecorativeIndex) in window.createWindowDecorativeRequestDtos"
                    :key="windowDecorativeIndex"
                    class="decorativeWindow"
                  >
                    <span style="color:lightcoral;font-size: 20px"> {{ windowDecorative.name }} </span>
                    <div style="display:flex;">
                      <div class="ordersService">
                        <b-form-checkbox
                          v-model="windowDecorative.orderOtherService.pattern"
                        />
                        <label style="font-size: 25px">{{ $t('InPattern') }} </label>
                      </div>
                      <div class="ordersService">
                        <b-form-checkbox
                          v-model="windowDecorative.orderOtherService.sewing"
                        />
                        <label style="font-size: 25px">{{ $t('Sewing') }} </label>
                      </div>
                      <div class="ordersService">
                        <b-form-checkbox
                          v-model="windowDecorative.orderOtherService.transport"
                        />
                        <label style="font-size: 25px">{{ $t('Transport') }} </label>
                      </div>
                      <div class="ordersService">
                        <b-form-checkbox
                          v-model="windowDecorative.orderOtherService.montage"
                        />
                        <label style="font-size: 25px">{{ $t('Montage') }} </label>
                      </div>
                    </div>
                    <div style="display: flex;gap:15px">
                      <b-form-group
                        :label="$t('MontageStart')"
                      >
                        <date-picker
                          v-model="windowDecorative.montageStart"
                          type="datetime"
                          :placeholder="$t('SelectD')"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('MontageEnd')"
                      >
                        <date-picker
                          v-model="windowDecorative.montageEnd"
                          type="datetime"
                          :placeholder="$t('SelectD')"
                        />
                      </b-form-group>
                    </div>
                    <div style="display: flex;gap:15px">
                      <b-form-group
                        :label="$t('Width')"
                      >
                        <b-form-input
                          v-model.number="windowDecorative.width"
                          :placeholder="$t('0')"
                          @input="updateMaterialLengthOrQuantity(windowDecorative)"
                        />
                        <p
                          v-if="windowDecorative.width < 0.0001"
                          class="validationClass"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        :label="$t('Height')"
                      >
                        <b-form-input
                          v-model.number="windowDecorative.height"
                          :placeholder="$t('0')"
                        />
                        <p
                          v-if="windowDecorative.height < 0.0001"
                          class="validationClass"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        :label="$t('SewingType')"
                        style="width: 30%;"
                      >
                        <vue-select
                          v-model="windowDecorative.sewingType"
                          :options="sewingTypes"
                          label="name"
                          :reduce="(e) => e.value"
                          :placeholder="$t('Select')"
                          @input="handleSewingMethod(windowDecorative)"
                        />
                        <p
                          v-if="windowDecorative.sewingType == null"
                          class="validationClass"
                        >
                          {{ $t('Please select a sewing type') }}
                        </p>
                      </b-form-group>
                    </div>
                    <div
                      v-for="(materialDecorative, materialIndexDecorative) in windowDecorative.materialVariantInStoreDtos"
                      :key="materialIndexDecorative"
                      class="materialsClass"
                    >
                      <div style="display:flex;justify-content: space-between;padding-right: 10px">
                        <label> {{ materialIndexDecorative + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ materialDecorative.parameter ? `: ${materialDecorative.parameter}`: null }} </span></label>
                        <b-icon-trash
                          style="cursor:pointer"
                          @click="removeMaterialDecorative(index, materialIndexDecorative)"
                        />
                      </div>
                      <b-form-group>
                        <b-form-radio-group
                          v-model="materialDecorative.measurementType"
                          style="display:flex;gap: 10px;width:90%"
                          @change="resetMaterialName(materialDecorative, window)"
                        >
                          <b-form-radio
                            class="form-radio"
                            value="Meter"
                          >
                            <p>{{ $t('Meter') }} </p>
                          </b-form-radio>
                          <b-form-radio
                            class="form-radio"
                            value="Part"
                          >
                            <p>{{ $t('Part') }} </p>
                          </b-form-radio>
                          <b-form-radio
                            class="form-radio"
                            value="Verse"
                          >
                            <p>{{ $t('Verse') }} </p>
                          </b-form-radio>
                          <b-form-radio
                            class="form-radio"
                            value="Package"
                          >
                            <p>{{ $t('Package') }} </p>
                          </b-form-radio>
                          <b-form-radio
                            class="form-radio"
                            value="Pair"
                          >
                            <p>{{ $t('Pair') }} </p>
                          </b-form-radio>
                          <b-form-radio
                            class="form-radio"
                            value="Set"
                          >
                            <p>{{ $t('Set') }} </p>
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                      <div style="display:flex;gap:10px;width:100%">
                        <b-form-group
                          :label="$t('MaterialVariant')"
                          style="width:25%"
                        >
                          <vue-select
                            v-model="materialDecorative.name"
                            :placeholder="$t('Description')"
                            label="materialVariantName"
                            :options="getMaterialOptions(materialDecorative.measurementType)"
                            @input="updateMaterialVariantId(materialDecorative.name, materialDecorative)"
                            @search="searchMaterial(materialDecorative.measurementType, $event)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <div
                                v-if="getMaterialVariantColorNameLike.length > 1"
                                style="display:flex;gap:5px; padding-top: 3px;width:98%"
                              >
                                <button
                                  class="buttonCancel"
                                  type="button"
                                  :disabled="pageNumberFilter == 1"
                                  @click="previousPage(materialDecorative.measurementType, searchQuery)"
                                >
                                  {{ $t('Previous') }}
                                </button>
                                <button
                                  class="buttonSubmit"
                                  type="button"
                                  @click="nextPage(materialDecorative.measurementType, searchQuery)"
                                >
                                  {{ $t('Next') }}
                                </button>
                              </div>
                            </li>
                          </vue-select>
                          <p
                            v-if="(materialDecorative.name == null || materialDecorative.name == '' )"
                            class="validationClass"
                          >
                            {{ $t('Please Select a material variant') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-if="materialDecorative.measurementType == 'Part' || materialDecorative.measurementType == 'Parts'"
                          :label="$t(materialDecorative.measurementType)"
                        >
                          <b-input
                            v-model.number="materialDecorative.quantity"
                            required
                            type="number"
                            @input="calculateTotalPerMeter(materialDecorative.pricePerMeter, materialDecorative.quantity)"
                          />
                          <p
                            v-if="materialDecorative.quantity < 0.0001"
                            class="validationClass"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else
                          :label="$t(materialDecorative.measurementType)"
                        >
                          <b-input
                            v-model.number="materialDecorative.length"
                            required
                            type="number"
                            @input="calculateTotalPerMeter(materialDecorative.pricePerMeter, materialDecorative.length)"
                          />
                          <p
                            v-if="materialDecorative.length < 0.0001"
                            class="validationClass"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          :label="$t('pricePerMeter')"
                        >
                          <b-input
                            v-model.number="materialDecorative.pricePerMeter"
                            required
                            disabled
                            type="number"
                            @input="calculateTotalPerMeter(materialDecorative.pricePerMeter, materialDecorative.length || materialDecorative.quantity)"
                          />
                        </b-form-group>
                        <b-form-group
                          :label="$t('PriceDiscount')"
                        >
                          <b-input
                            v-model.number="materialDecorative.pricePerMeterWithDiscount"
                            required
                            type="number"
                            @input="calculateTotalPerMeter(materialDecorative.pricePerMeterWithDiscount, materialDecorative.length || materialDecorative.quantity)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                    <div style="margin-top: 10px">
                      <button
                        v-if="window.createWindowDecorativeRequestDtos.length > 0"
                        class="buttonNotaSmall"
                        type="button"
                        @click="addMaterialObjectDecorative(index, window)"
                      >
                        Shto Material
                      </button>
                    </div>
                    <p
                      v-if="windowDecorative.materialVariantInStoreDtos.length == 0"
                      class="validationClass"
                    >
                      {{ $t('AddMaterial') }}
                    </p>
                  </div>
                </div>
              </div>
            </b-collapse>
            <div
              v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos.length > 0"
              class="buttonsCollapsed"
            >
              <b-button
                variant="link"
                class="collapse-button"
                @click="toggleCollapseObjectMaterials(index)"
              >
                <b-icon-chevron-down v-if="!isCollapsedObjectMaterials(index)" />
                <b-icon-chevron-up v-if="isCollapsedObjectMaterials(index)" />
              </b-button>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['createHouseItemRequestDtos', 'isButtonSubmited'],
  data() {
    return {
      totalPerMeter: 0,
      pageNumberFilter: 1,
      selectedFloorIndex: 0,
      selectedRoomIndex: 0,
      selectedWindowIndex: 0,
      collapsedStates: {},
      collapsedStatesFloor: {},
      collapsedStatesRoom: {},
      collapsedStatesObject: {},
      collapsedStatesObjectMaterials: {},
      hingeTypes: [
        { name: 'Decorative', value: 'Decorative' },
        { name: 'Closed', value: 'Closed' },
      ],
      sewingTypes: [
        {
          name: 'Sistemi Amerikan 3m', value: 'American_3m', meter: 3, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Sistemi Amerikan 2.8m', value: 'American_2_8m', meter: 2.8, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Fallta te rregullta 3m', value: 'FalltaReglar_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2.5m', value: 'FalltaReglar_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2m', value: 'FalltaReglar_2m', meter: 2, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta Zvicrrane 3m', value: 'FalltaSwiss_3m', meter: 3, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2.7m', value: 'FalltaSwiss_2_7m', meter: 2.7, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2m', value: 'FalltaSwiss_2_5m', meter: 2.5, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Qepje me vega 2.5m', value: 'Veg_2_5m', meter: 2.5, materialVariantId: '70ff128e-c889-424a-a6ed-08dd09677ee2', materialVariantName: 'Shirita me vega',
        },
        {
          name: 'Qepje Fallta me vrime 3m', value: 'Holes_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje Fallta me vrime 2.5m', value: 'Holes_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje me rrudhje 4m', value: 'Wrinkle_4m', meter: 4, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje me rrudhje 3m', value: 'Wrinkle_3m', meter: 3, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje shirit drejt', value: 'StraightBand', meter: 1, materialVariantId: '0974f5ce-e07e-4ac0-a6f0-08dd09677ee2', materialVariantName: 'Shirita drejt',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getMaterialsByArticleNum',
      'getMaterialVariantColorNameLike',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByPair',
      'getMaterialsVByVerse',
    ]),
  },
  mounted() {
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Meter',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Package',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Set',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Pair',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Verse',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Part',
    })
  },
  methods: {
    ...mapActions(['filterVariantByColor', 'resetAllFiltered', 'getMaterialVariantsToMDCPagination']),
    updateMaterialVariantId(value, material) {
      if (value != null) {
        material.materialVariantId = value.materialVariantId
        material.pricePerMeter = value.pricePerMeter
        material.name = value.materialVariantName
        material.pricePerMeterWithDiscount = value.pricePerMeter
      } else {
        material.materialVariantId = null
        material.pricePerMeter = 0
        material.pricePerMeterWithDiscount = 0
        material.name = null
      }
    },
    checkMontage(value, window) {
      if (value == false) {
        window.montageStart = null
        window.montageEnd = null
      }
    },
    updateDecorativeName(index, window) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[0].name = `${window.name} - ${window.hingType}`
    },
    updateDecorative(index, window) {
      if (window.hingType) {
        this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos.push({
          name: `${window.name} - ${window.hingType}`,
          width: 0,
          height: 0,
          sewingType: null,
          orderOtherService: {
            pattern: true,
            sewing: true,
            transport: true,
            montage: true,
          },
          materialVariantInStoreDtos: [
            {
              materialVariantId: null,
              name: null,
              measurementType: 'Meter',
              quantity: 0,
              length: 0,
              pricePerMeter: 0,
              pricePerMeterWithDiscount: 0,
            },
          ],
          montageStart: null,
          montageEnd: null,
        });
      } else {
        this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos = []
      }
    },
    toggleEditWindowName(index, floorIndex, roomIndex, windowIndex, window) {
      this.$set(this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos, windowIndex, {
        ...this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex],
        editable: !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].editable,
      });
      if (window.hingType) {
        this.updateDecorativeName(index, window)
      }
    },
    saveWindowName(index, floorIndex, roomIndex, windowIndex, window) {
      this.$set(this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos, windowIndex, {
        ...this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex],
        editable: false,
      });
      if (window.hingType) {
        this.updateDecorativeName(index, window)
      }
    },
    saveHouseName(index) {
      this.createHouseItemRequestDtos[index].editable = false;
    },
    toggleEditHouseName(index) {
      this.createHouseItemRequestDtos[index].editable = !this.createHouseItemRequestDtos[index].editable;
    },
    toggleEditFloorName(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable = !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable;
    },
    saveFloorName(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable = false;
    },
    toggleEditRoomName(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable = !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable;
    },
    saveRoomName(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable = false;
    },
    handleSewingMethod(window) {
      this.updateMaterialLengthOrQuantity(window)
      this.updateMaterialAmerican(window)
    },
    updateMaterialAmerican(window) {
      if (window.sewingType) {
        const selectedSewingType = this.sewingTypes.find(
          (type) => { return type.value === window.sewingType },
        );
        const existingItemIndex = window.materialVariantInStoreDtos.findIndex(
          (item) => { return item.parameter === 'Shirit' },
        );
        if (existingItemIndex !== -1) {
          window.materialVariantInStoreDtos[existingItemIndex] = {
            materialVariantId: selectedSewingType.materialVariantId,
            name: selectedSewingType.materialVariantName,
            measurementType: 'Meter',
            quantity: 0,
            length: selectedSewingType.meter * (window.width || 0),
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
            parameter: 'Shirit',
          };
        } else {
          window.materialVariantInStoreDtos.unshift({
            materialVariantId: selectedSewingType.materialVariantId,
            name: selectedSewingType.materialVariantName,
            measurementType: 'Meter',
            quantity: 0,
            length: selectedSewingType.meter * (window.width || 0),
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
            parameter: 'Shirit',
          });
        }
      } else {
        window.materialVariantInStoreDtos = window.materialVariantInStoreDtos.filter(
          (item) => { return item.parameter !== 'Shirit' },
        );
      }
    },
    updateMaterialLengthOrQuantity(window) {
      if (!window.sewingType || window.width == null || window.width === '') {
        window.materialVariantInStoreDtos.forEach((material) => {
          if (material.measurementType === 'Part') {
            material.quantity = 0;
          } else {
            material.length = 0;
          }
        });
      }
      const selectedSewingType = this.sewingTypes.find(
        (type) => { return type.value === window.sewingType },
      );
      if (selectedSewingType) {
        const meter = selectedSewingType.meter ?? 0;
        const width = window.width ?? 0;
        const calculatedValue = meter * width;
        window.materialVariantInStoreDtos.forEach((material) => {
          if (material.measurementType === 'Part') {
            material.quantity = calculatedValue;
            material.length = 0;
          } else {
            material.length = calculatedValue;
            material.quantity = 0;
          }
        });
      }
    },
    calculateTotalPerMeter(price, length) {
      this.totalPerMeter = price * length
    },
    resetMaterialName(material, window) {
      material.name = null;
      material.pricePerMeter = 0
      material.pricePerMeterWithDiscount = 0
      this.resetAllFiltered()
      this.updateMaterialLengthOrQuantity(window)
    },
    nextPage(measurement, query) {
      this.pageNumberFilter += 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    previousPage(measurement, query) {
      this.pageNumberFilter -= 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    searchMaterial(measurement, query) {
      this.searchQuery = query
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.filterVariantByColor({
          name: query,
          measurementType: measurement,
          pageNumber: 1,
          pageSize: 15,
        });
      }, 500);
    },
    getMaterialOptions(measurementType) {
      let measurement
      if (this.getMaterialVariantColorNameLike.length > 0) {
        measurement = this.getMaterialVariantColorNameLike
      } else if (measurementType === 'Meter') {
        measurement = this.getMaterialsVByMeter;
      } else if (measurementType === 'Package') {
        measurement = this.getMaterialsVByPackage;
      } else if (measurementType === 'Set') {
        measurement = this.getMaterialsVBySet;
      } else if (measurementType === 'Pair') {
        measurement = this.getMaterialsVByPair;
      } else if (measurementType === 'Verse') {
        measurement = this.getMaterialsVByVerse;
      } else {
        measurement = this.getMaterialsVByPart;
      }


      return measurement;
    },
    addFloor(index) {
      const newFloor = this.createHouseItemRequestDtos[index].createFloorRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.push({ name: `Kati nr ${newFloor}`, createRoomRequestDtos: [], editable: false });
    },
    removeHouse(index) {
      this.createHouseItemRequestDtos.splice(index, 1);
    },
    removeFloor(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.splice(floorIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0
    },
    removeMaterial(index, materialIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].materialVariantInStoreDtos.splice(materialIndex, 1)
    },
    removeMaterialDecorative(index, materialIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[0].materialVariantInStoreDtos.splice(materialIndex, 1)
    },
    selectFloorToAddRoom(index, floorIndex) {
      this.selectedFloorIndex = floorIndex;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    selectRoomToAddObject(index, floorIndex, roomIndex) {
      this.selectedRoomIndex = roomIndex;
      this.selectedWindowIndex = 0;
    },
    selectWindowToObject(index, floorIndex, roomIndex, windowIndex) {
      this.selectedWindowIndex = windowIndex;
    },
    addRoom(index) {
      const newRoom = this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos.push({ name: `Dhoma nr ${newRoom}`, createWindowRequestDtos: [], editable: false });
    },
    removeRoom(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.splice(roomIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    removeWindow(index, floorIndex, roomIndex, windowIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.splice(windowIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    addWindow(index) {
      const newWindow = this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos.push({
        name: `Objekti nr ${newWindow}`,
        width: 0,
        height: 0,
        sewingType: null,
        hingType: null,
        orderOtherService: {
          pattern: true,
          sewing: true,
          transport: true,
          montage: true,
        },
        materialVariantInStoreDtos: [
          {
            materialVariantId: null,
            name: null,
            measurementType: 'Meter',
            quantity: 0,
            length: 0,
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
          },
        ],
        montageStart: null,
        montageEnd: null,
        createWindowDecorativeRequestDtos: [],
      });
    },
    addMaterialObject(index, window) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
      this.handleSewingMethod(window)
    },
    addMaterialObjectDecorative(index, window) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[0].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
      this.handleSewingMethod(window)
    },
    toggleCollapse(index) {
      this.$set(this.collapsedStates, index, !this.collapsedStates[index]);
    },
    isCollapsed(index) {
      return !!this.collapsedStates[index];
    },
    toggleCollapseFloor(index) {
      this.$set(this.collapsedStatesFloor, index, !this.collapsedStatesFloor[index]);
    },
    isCollapsedFloor(index) {
      return !!this.collapsedStatesFloor[index];
    },
    toggleCollapseRoom(index) {
      this.$set(this.collapsedStatesRoom, index, !this.collapsedStatesRoom[index]);
    },
    isCollapsedRoom(index) {
      return !!this.collapsedStatesRoom[index];
    },
    toggleCollapseObject(index) {
      this.$set(this.collapsedStatesObject, index, !this.collapsedStatesObject[index]);
    },
    isCollapsedObject(index) {
      return !!this.collapsedStatesObject[index];
    },
    toggleCollapseObjectMaterials(index) {
      this.$set(this.collapsedStatesObjectMaterials, index, !this.collapsedStatesObjectMaterials[index]);
    },
    isCollapsedObjectMaterials(index) {
      return !!this.collapsedStatesObjectMaterials[index];
    },
  },
}
</script>

  <style scoped>
  .validationClass{
    color: red;
    padding-top: 5px;
    margin-bottom: 0px;
  }
  .materialsClass{
    display:flex;
    flex-direction: column;
    gap:10px;
  }
  .allMaterialsDetails{
    display:flex;
    flex-direction: column;
    gap:15px;
    margin-top: 15px;
  }
  .allClases {
  display:flex;
  gap:15px;
  flex-wrap:wrap;
  }
  .form-radio{
    display:flex;
    align-items: center;
    gap:5px;
    width:100%;
  }
  .form-radio p {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .orderOtherServiceClass{
    display:flex;
    gap:3px;
    width:100%
  }
  .nameFloor{
      height: 100%;
      display: flex;
      flex-direction: column;
  }

  .iconsFloor{
      width:100%;
      display:flex;
      justify-content: space-between;
  }

.decorativeWindow{
  display:flex;
  flex-direction:column;
  gap:10px;
  border:1px solid lightgrey;
  border-radius:12px;
  padding:10px;
}

  .sectionClass{
      border: 1px solid lightgrey;
      padding: 10px;
      border-radius: 12px;
  }

  .hrClass{
      color:gray;
  }

  .iconsClass{
      display:flex;
      gap:10px;
      align-items:center;
  }
.ordersService{
  display:flex;
      gap:10px;
      align-items:center;
      width:100%
}
  .homeEdit{
      display:flex;
      gap:5px;
      justify-content: space-between;
  }

  .homeName{
      display:flex;
      gap:5px;
      width:15%;
      align-items:center;
  }

  .floorWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.objectsClass, .selectedFloor, .buttonNota {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
  padding: 15px;
  width: 140px;
  min-height: 140px;
  height: 100%;
}

.objectsClass {
  border: 1px solid lightgrey;
  color: black;
}

.selectedFloor {
  border: 1px solid red;
}

  .collapse-button {
    padding: 0;
  }
  .houseDetail{
    display:flex;
    flex-direction: column;
    gap:10px;
  }
  .buttonsCollapsed{
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
  }
  .buttonAndCollapse{
    display:flex;
    justify-content: space-between
  }
  .buttonOnly{
    display: flex;
    justify-content: space-between
  }
  </style>
